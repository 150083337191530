import { render, staticRenderFns } from "./ApiItem.vue?vue&type=template&id=6049f8d3&scoped=true&"
import script from "./ApiItem.vue?vue&type=script&lang=js&"
export * from "./ApiItem.vue?vue&type=script&lang=js&"
import style0 from "./ApiItem.vue?vue&type=style&index=0&id=6049f8d3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6049f8d3",
  null
  
)

export default component.exports